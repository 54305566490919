export default [
  {
    code: 401,
    message: `errors.expired`,
  },
  {
    code: 403,
    message: `errors.languageNotSupported`,
  },
  {
    code: 429,
    message: `errors.exceededLimit`,
  },
  {
    code: 7401,
    message: `errors.refresh`,
  },
];

export class ApiError extends Error {
  constructor(response) {
    super();
    this.response = response;
  }
}

export const fallbackErrorMsg = `errors.unknown`;

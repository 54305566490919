import React from 'react';
import './Toast.scss';
import PropTypes from 'prop-types';

function Toast(props) {
  const className = props.visible ? `Toast Toast--visible` : `Toast`;

  return (
    <div className={className}>
      { props.content }
    </div>
  );
}

Toast.propTypes = {
  content: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default Toast;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './ErrorList.scss';
import AppContext from '../../context/app-context';
import CorrectionBox from '../CorrectionBox';
import ErrorListEmpty from './ErrorListEmpty';
import ErrorCount from '../ErrorCount';
import LanguageSwitch from '../LanguageSwitch';
import MoodDetectButton from '../moods/MoodDetectButton';
import Translation from '../translation/Translation';
import CharacterCount from '../CharacterCount';

function ErrorList(props) {
  const { state, actions } = useContext(AppContext);
  const className = props.className ? `ErrorList ${props.className}` : `ErrorList`;

  const handleApply = index => {
    actions.setCurrentCorrection(index);
    actions.applyCorrection();
  };

  const handleDiscard = index => {
    actions.setCurrentCorrection(index);
    actions.discardCorrection();
  };

  return (
    <div className={className}>
      <div className="ErrorList__list">
        { state.apiCorrection.edits.length > 0 ? (
          state.apiCorrection.edits.map((item, index, arr) => (
            <CorrectionBox
              key={`error-list-box-${index}`}
              className={index === arr.length - 1 ? `ErrorList__correction-box` : `ErrorList__correction-box ErrorList__correction-box--bordered`}
              data={{
                corrected: item.display.correctedDisplay,
                original: item.display.originalDisplay,
                type: item.type,
              }}
              isModal={false}
              onApply={() => handleApply(index)}
              onDiscard={() => handleDiscard(index)}
              onMouseEnter={() => actions.setCurrentCorrectionIndex(index)}
              onMouseLeave={() => actions.setCurrentCorrectionIndex(null)} />
          ))
        ) : <ErrorListEmpty />}
      </div>
      <div className="ErrorList__footer">
        <div className="ErrorList__footer-left">
          <ErrorCount />
          <LanguageSwitch />
        </div>
        <div className="ErrorList__footer-right">
          <CharacterCount />
          <Translation />
          <MoodDetectButton />
        </div>
      </div>
    </div>
  );
}

ErrorList.defaultProps = {
  className: ``,
};

ErrorList.propTypes = {
  className: PropTypes.string,
};

export default ErrorList;

import React, { useContext, useEffect } from 'react';
import './MoodData.scss';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import MoodItem from './MoodItem';
import AppContext from '../../context/app-context';
import config from '../../config/config';
import useApi from '../hooks/useApi';
import { assembleDetectMoodPayload } from '../../helpers/request-helper';

function MoodData() {
  const { actions, state } = useContext(AppContext);
  const { loading, response } = useApi({
    block: [state.apiMoods.length > 0],
    payload: assembleDetectMoodPayload(state),
    resource: config.api.typeright.resources.moodDetection,
    signal: [true],
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (response) {
      actions.setApiMoods(response);
    }
  }, [response]);

  return (
    <div className="MoodData">
      <span className="MoodData__heading">{ t(`moodDetection.headline`) }</span>
      <div className="MoodData__separator" />
      <p className="MoodData__info">
        { t(`moodDetection.description`) }
      </p>

      {loading ? (
        <div className="MoodData__loading">
          <Lottie
            options={config.lottieDefaultOptions}
            style={{ height: `40px`, width: `40px` }}
            isClickToPauseDisabled />
        </div>
      ) : (
        <>
          <div className="MoodData__emotions">
            {state.apiMoods.map((item, index) => (
              <MoodItem
                key={`single-mood-${index}`}
                mood={item} />
            ))}
          </div>
          <div className="MoodData__percentages">
            {state.apiMoods.map((item, index) => (
              <div
                key={`single-mood-percentage-${index}`}
                className="MoodData__percentages-row">
                <span>{ t(item.label) }</span>
                <span>{`${item.score}%`}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default MoodData;

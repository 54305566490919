import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './LanguageSwitch.scss';
import { useTranslation } from 'react-i18next';
import config from '../config/config';
import AppContext from '../context/app-context';
import checkmark from '../assets/svgs/checkmark.svg';
import CommonHelper from '../helpers/common-helper';
import FooterModal from './common/FooterModal';

function LanguageSwitch() {
  const modal = config.modals.LANGUAGE_SWITCH;
  const { actions, state } = useContext(AppContext);
  const languages = CommonHelper.assembleSourceLanguages(state.assets.flags);
  const { t } = useTranslation();
  const elModal = useRef(null);
  const [languageImage, setLanguageImage] = useState(null);

  const handleUpdateLanguage = index => {
    actions.toggleModal(modal);

    if (languages[index].dialect === state.languages.source.dialect) {
      return;
    }

    actions.setLanguagesSource(languages[index]);
  };

  useEffect(() => {
    actions.setLanguagesSource(languages.find(item => item.dialect === `auto`));

    const demoText = localStorage.getItem(`lang`) === `de`
      ? `Hier kanst du unsere tolle Grammatikkorrektur tesden!`
      : `Here you can tesd our awesome grammar corection!`;

    actions.setSourceText(demoText);
  }, []);

  useEffect(() => {
    if (!state.languages.source.dialect) {
      return;
    }

    if (state.apiCorrection.detected) {
      setLanguageImage(CommonHelper.assembleSingleLanguage(state.assets.flags, state.apiCorrection.detected).image);
      return;
    }

    setLanguageImage(CommonHelper.assembleSingleLanguage(state.assets.flags, state.languages.source.dialect).image);
  }, [state.languages.source, state.apiCorrection.detected]);

  const handleCloseModals = event => {
    if (!elModal.current) {
      return;
    }

    if (!elModal.current.contains(event.target)) {
      actions.toggleModal();
    }
  };

  useEffect(() => {
    if (state.modals[modal]) {
      document.addEventListener(`click`, handleCloseModals);
    }
    return () => document.removeEventListener(`click`, handleCloseModals);
  }, [state.modals[modal]]);

  return (
    <div className="LanguageSwitch">
      { state.modals[modal] && <div className="LanguageSwitch__triangle" />}
      <div
        className="LanguageSwitch__toggle"
        onClick={() => actions.toggleModal(modal)}>
        <img
          alt=""
          className="LanguageSwitch__toggle-img"
          src={languageImage} />
        { state.languages.source.dialect === `auto` && (
          <span className="LanguageSwitch__toggle-auto">AUTO</span>
        )}
      </div>

      {state.modals[modal] && (
        <FooterModal ref={elModal}>
          <div className="LanguageSwitch__list">
            {languages.map((item, index) => (
              <div
                key={`language-switch-list-item-${index}`}
                className={index > 0 ? `LanguageSwitch__list-item LanguageSwitch__list-item--bordered` : `LanguageSwitch__list-item`}
                onClick={() => handleUpdateLanguage(index)}>
                <span>{t(item.label)}</span>
                {item.dialect === state.languages.source.dialect && (
                  <img
                    alt=""
                    src={checkmark} />
                )}
              </div>
            ))}
          </div>
        </FooterModal>
      )}
    </div>
  );
}

export default LanguageSwitch;

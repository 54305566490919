import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en/translation.json';
import de from './locales/de/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: process.env.REACT_APP_ENV !== `production`,
    detection: {
      caches: [`localStorage`],
      lookupLocalStorage: `lang`,
      order: [`localStorage`],
    },
    fallbackLng: `en`,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      de: { translation: de },
      en: { translation: en },
    },
  });

export default i18n;

import React from 'react';
import './FooterModal.scss';
import PropTypes from 'prop-types';

const FooterModal = React.forwardRef((props, ref) => {
  const className = props.mode === `left` ? `FooterModal FooterModal--left` : `FooterModal`;

  return (
    <div
      className={className}
      ref={ref}>
      {props.children}
    </div>
  );
});

FooterModal.defaultProps = {
  mode: `left`,
};

FooterModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  mode: PropTypes.string,
};

export default FooterModal;

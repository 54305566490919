import CommonHelper from './common-helper';

class UiHelper {
  static assembleCorrections(rawEdits, jsxEdits, sourceText) {
    const indicator = Date.now() * Math.random();
    let tempSourceText = sourceText;
    let offset = 0;

    rawEdits.forEach((item, index) => {
      if (index > 0) {
        const previous = rawEdits[index - 1].original;
        offset += `[jsx]${indicator}${index - 1}${indicator}[jsx]`.length - previous.length;
      }
      tempSourceText = CommonHelper.replaceByIndex(item.start + offset, item.end + offset, tempSourceText, `[jsx]${indicator}${index}${indicator}[jsx]`);
    });

    return tempSourceText.split(`[jsx]`).map(item => {
      if (!item.includes(indicator)) {
        return item;
      }
      return jsxEdits[parseInt(item.replaceAll(indicator, ``), 10)];
    });
  }

  static assembleSynonyms(rawSynonyms, jsxSynonyms, sourceText) {
    const indicator = Date.now() * Math.random();
    let tempSourceText = sourceText;
    let offset = 0;

    rawSynonyms.forEach((item, index) => {
      if (index > 0) {
        const previous = rawSynonyms[index - 1].word;
        offset += `[jsx]${indicator}${index - 1}${indicator}[jsx]`.length - previous.length;
      }
      tempSourceText = CommonHelper.replaceByIndex(item.start + offset, item.end + offset, tempSourceText, `[jsx]${indicator}${index}${indicator}[jsx]`);
    });

    return tempSourceText.split(`[jsx]`).map(item => {
      if (!item.includes(indicator)) {
        return item;
      }
      return jsxSynonyms[parseInt(item.replaceAll(indicator, ``), 10)];
    });
  }

  static getHoverModalStyle(elSpanReference, elInput) {
    let left = elSpanReference.offsetLeft + ((200 - elSpanReference.offsetWidth) / -2);
    let right = `unset`;

    if (left < 0) {
      left = 0;
      right = `unset`;
    }

    if (left + 200 > window.innerWidth) {
      left = `unset`;
      right = 0;
    }

    return {
      bottom: `unset`,
      left,
      right,
      top: elSpanReference.offsetTop + elSpanReference.offsetHeight + 14 - elInput.scrollTop,
      width: `200px`,
    };
  }
}

export default UiHelper;

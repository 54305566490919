import loadingAnimation from '../assets/json/loading.json';

export default {
  api: {
    accounts: {
      paths: { token: `oauth/token` },
    },
    typeright: {
      paths: {
        autocorrect: `/autocorrect/v1`,
        moodDetection: `/detect_mood/v1`,
        synonyms: `/contextual_synonyms/v1`,
        translate: `/translate/v1`,
      },
      // Used in custom useApi.jsx hook to specify path as well as response transformer.
      resources: {
        autocorrect: `autocorrect`,
        moodDetection: `moodDetection`,
        synonyms: `synonyms`,
        translate: `translate`,
      },
    },
  },
  languages: {
    autoCorrect: [
      {
        dataImage: `Auto`,
        dialect: `auto`,
        label: `languages.auto`,
      },
      {
        dataImage: `en-US-UK`,
        dialect: `en`,
        label: `languages.en`,
      },
      {
        dataImage: `de`,
        dialect: `de`,
        label: `languages.de`,
      },
    ],
    translation: [
      {
        dataImage: `af`,
        dialect: `af`,
        label: `languages.af`,
      },
      {
        dataImage: `sq`,
        dialect: `sq`,
        label: `languages.sq`,
      },
      {
        dataImage: `ar-AE`,
        dialect: `ar-AE`,
        label: `languages.ar-AE`,
      },
      {
        dataImage: `az`,
        dialect: `az`,
        label: `languages.az`,
      },
      {
        dataImage: `bn`,
        dialect: `bn`,
        label: `languages.bn`,
      },
      {
        dataImage: `bs`,
        dialect: `bs`,
        label: `languages.bs`,
      },
      {
        dataImage: `bg`,
        dialect: `bg`,
        label: `languages.bg`,
      },
      {
        dataImage: `zh-CN`,
        dialect: `zh-CN`,
        label: `languages.zh-CN`,
      },
      {
        dataImage: `zh-TW`,
        dialect: `zh-TW`,
        label: `languages.zh-TW`,
      },
      {
        dataImage: `hr`,
        dialect: `hr`,
        label: `languages.hr`,
      },
      {
        dataImage: `cs`,
        dialect: `cs`,
        label: `languages.cs`,
      },
      {
        dataImage: `da`,
        dialect: `da`,
        label: `languages.da`,
      },
      {
        dataImage: `nl`,
        dialect: `nl`,
        label: `languages.nl`,
      },
      {
        dataImage: `en-US-UK`,
        dialect: `en`,
        label: `languages.en`,
      },
      {
        dataImage: `et`,
        dialect: `et`,
        label: `languages.et`,
      },
      {
        dataImage: `fi`,
        dialect: `fi`,
        label: `languages.fi`,
      },
      {
        dataImage: `fr-FR`,
        dialect: `fr-FR`,
        label: `languages.fr-FR`,
      },
      {
        dataImage: `de`,
        dialect: `de`,
        label: `languages.de`,
      },
      {
        dataImage: `el`,
        dialect: `el`,
        label: `languages.el`,
      },
      {
        dataImage: `iw`,
        dialect: `he`,
        label: `languages.he`,
      },
      {
        dataImage: `hi`,
        dialect: `hi`,
        label: `languages.hi`,
      },
      {
        dataImage: `hu`,
        dialect: `hu`,
        label: `languages.hu`,
      },
      {
        dataImage: `is`,
        dialect: `is`,
        label: `languages.is`,
      },
      {
        dataImage: `id`,
        dialect: `id`,
        label: `languages.id`,
      },
      {
        dataImage: `it`,
        dialect: `it`,
        label: `languages.it`,
      },
      {
        dataImage: `ja`,
        dialect: `ja`,
        label: `languages.ja`,
      },
      {
        dataImage: `ko`,
        dialect: `ko`,
        label: `languages.ko`,
      },
      {
        dataImage: `lv`,
        dialect: `lv`,
        label: `languages.lv`,
      },
      {
        dataImage: `lt`,
        dialect: `lt`,
        label: `languages.lt`,
      },
      {
        dataImage: `mk`,
        dialect: `mk`,
        label: `languages.mk`,
      },
      {
        dataImage: `ms`,
        dialect: `ms`,
        label: `languages.ms`,
      },
      {
        dataImage: `no`,
        dialect: `no`,
        label: `languages.no`,
      },
      {
        dataImage: `fa`,
        dialect: `fa`,
        label: `languages.fa`,
      },
      {
        dataImage: `pl`,
        dialect: `pl`,
        label: `languages.pl`,
      },
      {
        dataImage: `pt-BR`,
        dialect: `pt-BR`,
        label: `languages.pt-BR`,
      },
      {
        dataImage: `pt-PT`,
        dialect: `pt-PT`,
        label: `languages.pt-PT`,
      },
      {
        dataImage: `ro`,
        dialect: `ro`,
        label: `languages.ro`,
      },
      {
        dataImage: `ru`,
        dialect: `ru`,
        label: `languages.ru`,
      },
      {
        dataImage: `sr`,
        dialect: `sr`,
        label: `languages.sr`,
      },
      {
        dataImage: `sk`,
        dialect: `sk`,
        label: `languages.sk`,
      },
      {
        dataImage: `sl`,
        dialect: `sl`,
        label: `languages.sl`,
      },
      {
        dataImage: `es-ES`,
        dialect: `es-ES`,
        label: `languages.es-ES`,
      },
      {
        dataImage: `sv`,
        dialect: `sv`,
        label: `languages.sv`,
      },
      {
        dataImage: `sw`,
        dialect: `sw`,
        label: `languages.sw`,
      },
      {
        dataImage: `tl`,
        dialect: `tl`,
        label: `languages.tl`,
      },
      {
        dataImage: `th`,
        dialect: `th`,
        label: `languages.th`,
      },
      {
        dataImage: `tr`,
        dialect: `tr`,
        label: `languages.tr`,
      },
      {
        dataImage: `uk`,
        dialect: `uk`,
        label: `languages.uk`,
      },
      {
        dataImage: `ur`,
        dialect: `ur`,
        label: `languages.ur`,
      },
      {
        dataImage: `vi`,
        dialect: `vi`,
        label: `languages.vi`,
      },
      {
        dataImage: `ka`,
        dialect: `ka`,
        label: `languages.ka`,
      },
      {
        dataImage: `so`,
        dialect: `so`,
        label: `languages.so`,
      },
      {
        dataImage: `my`,
        dialect: `my`,
        label: `languages.my`,
      },
      {
        dataImage: `ta`,
        dialect: `ta`,
        label: `languages.ta`,
      },
      {
        dataImage: `ne`,
        dialect: `ne`,
        label: `languages.ne`,
      },
      {
        dataImage: `mn`,
        dialect: `mn`,
        label: `languages.mn`,
      },
    ],
  },
  lottieDefaultOptions: {
    animationData: loadingAnimation,
    autoplay: true,
    loop: true,
    rendererSettings: {
      preserveAspectRatio: `xMidYMid slice`,
    },
  },
  mistakeTypes: [
    { key: `R:ADJ`, localization: `mistakeTypes.R:ADJ` },
    { key: `R:ADJ:FORM`, localization: `mistakeTypes.R:ADJ:FORM` },
    { key: `R:ADV`, localization: `mistakeTypes.R:ADV` },
    { key: `R:CONJ`, localization: `mistakeTypes.R:CONJ` },
    { key: `R:CONTR`, localization: `mistakeTypes.R:CONTR` },
    { key: `R:DET`, localization: `mistakeTypes.R:DET` },
    { key: `R:MORPH`, localization: `mistakeTypes.R:MORPH` },
    { key: `R:NOUN`, localization: `mistakeTypes.R:NOUN` },
    { key: `R:NOUN:INFL`, localization: `mistakeTypes.R:NOUN:INFL` },
    { key: `R:NOUN:NUM`, localization: `mistakeTypes.R:NOUN:NUM` },
    { key: `R:NOUN:POSS`, localization: `mistakeTypes.R:NOUN:POSS` },
    { key: `R:ORTH`, localization: `mistakeTypes.R:ORTH` },
    { key: `R:OTHER`, localization: `mistakeTypes.R:OTHER` },
    { key: `R:PART`, localization: `mistakeTypes.R:PART` },
    { key: `R:PREP`, localization: `mistakeTypes.R:PREP` },
    { key: `R:PRON`, localization: `mistakeTypes.R:PRON` },
    { key: `R:PUNCT`, localization: `mistakeTypes.R:PUNCT` },
    { key: `R:SPELL`, localization: `mistakeTypes.R:SPELL` },
    { key: `R:UNK`, localization: `mistakeTypes.R:UNK` },
    { key: `R:VERB`, localization: `mistakeTypes.R:VERB` },
    { key: `R:VERB:FORM`, localization: `mistakeTypes.R:VERB:FORM` },
    { key: `R:VERB:INFL`, localization: `mistakeTypes.R:VERB:INFL` },
    { key: `R:VERB:SVA`, localization: `mistakeTypes.R:VERB:SVA` },
    { key: `R:VERB:TENSE`, localization: `mistakeTypes.R:VERB:TENSE` },
    { key: `R:WO`, localization: `mistakeTypes.R:WO` },
    { key: `M:ADJ`, localization: `mistakeTypes.M:ADJ` },
    { key: `M:ADV`, localization: `mistakeTypes.M:ADV` },
    { key: `M:CONJ`, localization: `mistakeTypes.M:CONJ` },
    { key: `M:CONTR`, localization: `mistakeTypes.M:CONTR` },
    { key: `M:DET`, localization: `mistakeTypes.M:DET` },
    { key: `M:NOUN`, localization: `mistakeTypes.M:NOUN` },
    { key: `M:NOUN:POSS`, localization: `mistakeTypes.M:NOUN:POSS` },
    { key: `M:OTHER`, localization: `mistakeTypes.M:OTHER` },
    { key: `M:PART`, localization: `mistakeTypes.M:PART` },
    { key: `M:PREP`, localization: `mistakeTypes.M:PREP` },
    { key: `M:PRON`, localization: `mistakeTypes.M:PRON` },
    { key: `M:PUNCT`, localization: `mistakeTypes.M:PUNCT` },
    { key: `M:UNK`, localization: `mistakeTypes.M:UNK` },
    { key: `M:VERB`, localization: `mistakeTypes.M:VERB` },
    { key: `M:VERB:FORM`, localization: `mistakeTypes.M:VERB:FORM` },
    { key: `M:VERB:TENSE`, localization: `mistakeTypes.M:VERB:TENSE` },
    { key: `U:ADJ`, localization: `mistakeTypes.U:ADJ` },
    { key: `U:ADV`, localization: `mistakeTypes.U:ADV` },
    { key: `U:CONJ`, localization: `mistakeTypes.U:CONJ` },
    { key: `U:CONTR`, localization: `mistakeTypes.U:CONTR` },
    { key: `U:DET`, localization: `mistakeTypes.U:DET` },
    { key: `U:NOUN`, localization: `mistakeTypes.U:NOUN` },
    { key: `U:NOUN:POSS`, localization: `mistakeTypes.U:NOUN:POSS` },
    { key: `U:OTHER`, localization: `mistakeTypes.U:OTHER` },
    { key: `U:PART`, localization: `mistakeTypes.U:PART` },
    { key: `U:PREP`, localization: `mistakeTypes.U:PREP` },
    { key: `U:PRON`, localization: `mistakeTypes.U:PRON` },
    { key: `U:PUNCT`, localization: `mistakeTypes.U:PUNCT` },
    { key: `U:UNK`, localization: `mistakeTypes.U:UNK` },
    { key: `U:VERB`, localization: `mistakeTypes.U:VERB` },
    { key: `U:VERB:FORM`, localization: `mistakeTypes.U:VERB:FORM` },
    { key: `U:VERB:TENSE`, localization: `mistakeTypes.U:VERB:TENSE` },
    { key: `U:SPACE`, localization: `mistakeTypes.U:SPACE` },
  ],
  modals: {
    EMOTIONS: `emotions`,
    ERROR_COUNT: `errorCount`,
    LANGUAGE_SWITCH: `languageSwitch`,
    TRANSLATION: `translation`,
  },
  moodLabels: [
    { source: `anger`, target: `Angry` },
    { source: `anticipation`, target: `Hopeful` },
    { source: `disgust`, target: `Disgusted` },
    { source: `fear`, target: `Fearful` },
    { source: `joy`, target: `Joyful` },
    { source: `love`, target: `Affectionate` },
    { source: `optimism`, target: `Optimistic` },
    { source: `pessimism`, target: `Pessimistic` },
    { source: `sadness`, target: `Sad` },
    { source: `surprise`, target: `Surprised` },
    { source: `trust`, target: `Trustworthy` },
  ],
};

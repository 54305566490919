import React, { useContext } from 'react';
import './CharacterCount.scss';
import AppContext from '../context/app-context';

function CharacterCount() {
  const { state } = useContext(AppContext);
  const className = state.sourceText.length >= 1000
    ? `CharacterCount CharacterCount--negative`
    : `CharacterCount`;

  return (
    <div className={className}>
      {state.sourceText.length}
      /1000
    </div>
  );
}

export default CharacterCount;

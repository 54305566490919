import { useEffect, useState } from 'react';
import axios from 'axios';
import ApiService from '../../api/api-service';
import config from '../../config/config';
import transformer from '../../helpers/transform-helper';
import CommonHelper from '../../helpers/common-helper';
import AuthService from '../../api/auth-service';

/**
 * @param params Determines behaviour of request.
 * @param {any} params.payload
 * @param {(boolean | function)[]} [params.block=undefined] Array of functions or booleans which block request if true is by either value/function.
 * @param {string} params.resource Determines which resource is fetched/response transformer is used.
 * @param {any} [params.signal=undefined] Value which triggers new request.
 * @param {number} [params.timeout=1000] Timeout of debouncer.
 */
function useApi(params) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const env = process.env.REACT_APP_ENV;
  const abortController = new AbortController();
  const apiService = new ApiService({
    baseURL: process.env.REACT_APP_TYPERIGHT_API,
    headers: {
      "Content-Type": `application/json`,
    },
  });

  apiService.axios.interceptors.request.use(
    // ToDo: Introduce staging environment.
    reqConfig => {
      if (env === `production`) {
        return AuthService.updateProdAuthentication(reqConfig);
      }

      return AuthService.updateAuthentication(reqConfig);
    },
    reqError => Promise.reject(reqError),
  );

  useEffect(() => {
    setError(null);
    setLoading(false);
    setResponse(null);

    if (!CommonHelper.evaluateConditionsArray(params.signal)) {
      return;
    }

    if (params.block && CommonHelper.evaluateConditionsArray(params.block)) {
      return;
    }

    setLoading(true);

    const debounce = setTimeout(async () => {
      try {
        const resp = await apiService.post({
          data: params.payload, // ToDo: Data Generator
          signal: abortController.signal,
          url: config.api.typeright.paths[params.resource],
        });
        setResponse(transformer[params.resource](resp));
        setLoading(false);
      } catch (err) {
        if (!err || axios.isCancel(err)) {
          return;
        }

        setError(CommonHelper.handleApiError(err));
        setLoading(false);
      }
    }, params?.timeout ?? 1000);

    return () => {
      clearTimeout(debounce);
      abortController.abort();
    };
  }, params.signal);

  return {
    error,
    loading,
    response,
  };
}

export default useApi;

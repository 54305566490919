import React, {
  createRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import './TranslationInput.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppContext from '../../context/app-context';

function TranslationInput(props) {
  const { state } = useContext(AppContext);
  const elWrapper = createRef();
  const elInput = createRef();
  const elResult = createRef();
  const [wrapperStyle, setWrapperStyle] = useState({ maxHeight: `100%` });
  const [placeholder, setPlaceholder] = useState(``);
  const { t } = useTranslation();

  const handleTextClear = () => {
    props.handleSetText(``);
    elInput.current.innerText = ``;
  };

  useEffect(() => {
    setWrapperStyle({
      maxHeight: getComputedStyle(elWrapper.current).height,
    });
  }, []);

  useEffect(() => {
    if (!props.targetText) {
      return;
    }

    elWrapper.current.scrollTop = elResult.current.offsetTop - 100;
  }, [props.targetText]);

  useEffect(() => {
    const source = state.languages.source.dialect === `auto`
      ? state.apiCorrection.detected || `en`
      : state.languages.source.dialect;

    setPlaceholder(t(`translation.placeholder`, { source: t(state.languages.target.label), target: t(`languages.${source}`) }));
  }, [state.languages.source]);

  return (
    <div className="TranslationInput">
      <div
        className="TranslationInput__wrapper"
        ref={elWrapper}
        style={wrapperStyle}>
        <p
          className="TranslationInput__input"
          data-placeholder={placeholder}
          ref={elInput}
          contentEditable
          onInput={event => { props.handleSetText(event.target.innerText); }} />
        <p
          className="TranslationInput__result"
          ref={elResult}>
          { props.targetText }
        </p>
      </div>
      <div className="TranslationInput__footer">
        <span
          className="TranslationInput__footer-button"
          onClick={handleTextClear}>
          { t(`translation.clear`) }
        </span>
      </div>
    </div>
  );
}

TranslationInput.propTypes = {
  handleSetText: PropTypes.func.isRequired,
  targetText: PropTypes.string.isRequired,
};

export default TranslationInput;

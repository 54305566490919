import React from 'react';
import './TranslationLanguageList.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TranslationLanguage from './TranslationLanguage';

function TranslationLanguageList(props) {
  const { t } = useTranslation();

  const className = props.visible
    ? `LanguageList LanguageList--visible`
    : `LanguageList`;

  const wrapperClass = props.visible
    ? `LanguageList__wrapper LanguageList__wrapper--visible`
    : `LanguageList__wrapper`;

  return (
    <div className={className}>
      <div className={wrapperClass}>
        <div className="LanguageList__header">
          <span>{ t(`translation.select`) }</span>
          <span
            className="LanguageList__header-close"
            onClick={props.handleToggleList}>
            { t(`translation.done`) }
          </span>
        </div>
        <div className="LanguageList__separator" />
        <div className="LanguageList__list">
          {props.languages.map((item, index) => (
            <TranslationLanguage
              key={`language-list-item-${index}`}
              image={item.image}
              label={item.label}
              selected={item.dialect === props.dialect}
              onClick={() => props.handleUpdateLanguage(item.dialect)} />
          ))}
        </div>
      </div>
    </div>
  );
}

TranslationLanguageList.propTypes = {
  dialect: PropTypes.string.isRequired,
  handleToggleList: PropTypes.func.isRequired,
  handleUpdateLanguage: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.exact({
    dataImage: PropTypes.string,
    dialect: PropTypes.string,
    image: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default TranslationLanguageList;

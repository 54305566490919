import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import './Triangle.scss';
import PropTypes from 'prop-types';
import AppContext from '../../context/app-context';

function Triangle(props) {
  const { state } = useContext(AppContext);
  const className = `Triangle ${props.color === `gray` ? `Triangle--gray` : ``} ${props.size === `large` ? `Triangle--large` : ``}`;
  const [style, setStyle] = useState(null);

  useEffect(() => {
    if (!props.elSpan) {
      return;
    }
    setStyle({
      left: (props.elSpan.offsetWidth - 40) / 2,
    });
  }, []);

  return (
    props.modal
      ? state.modals[props.modal] && <div className={className} />
      : (
        <div
          className={className}
          style={style} />
      )
  );
}

Triangle.defaultProps = {
  color: `white`,
  elSpan: null,
  modal: null,
  size: ``,
};

Triangle.propTypes = {
  color: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  elSpan: PropTypes.object,
  modal: PropTypes.string,
  size: PropTypes.string,
};

export default Triangle;

import React, {
  useContext,
  useEffect,
  useRef,
} from 'react';
import './MoodDetectButton.scss';
import AppContext from '../../context/app-context';
import config from '../../config/config';
import '../../styles/variables.scss';
import MoodPreview from './MoodPreview';
import MoodData from './MoodData';
import FooterModal from '../common/FooterModal';
import Triangle from '../common/Triangle';

function MoodDetectButton() {
  const {
    actions,
    derived,
    state,
  } = useContext(AppContext);
  const modal = config.modals.EMOTIONS;
  const elModal = useRef(null);

  const handleToggleModal = () => {
    actions.toggleModal(modal);
  };

  const modalContent = derived.isFullyCorrected()
    ? <MoodData />
    : <MoodPreview handleToggleModal={handleToggleModal} />;

  const handleCloseModals = event => {
    if (!elModal.current) {
      return;
    }

    if (!elModal.current.contains(event.target)) {
      actions.toggleModal();
    }
  };

  useEffect(() => {
    if (state.modals[modal]) {
      document.addEventListener(`click`, handleCloseModals);
    }
    return () => document.removeEventListener(`click`, handleCloseModals);
  }, [state.modals[modal]]);

  return (
    <div className="MoodDetectButton">
      <Triangle
        color="gray"
        modal={modal} />
      <div
        className="MoodDetectButton__toggle"
        onClick={handleToggleModal} />

      {state.modals[modal] && (
        <FooterModal
          mode="right"
          ref={elModal}>
          {modalContent}
        </FooterModal>
      )}
    </div>
  );
}

export default MoodDetectButton;

export default class ImportHelper {
  static importAssets() {
    const flagPaths = require.context(`../assets/svgs/flag-icons/`, false, /\.svg$/);
    const flagImages = flagPaths.keys().map(item => ({
      dataImage: item.replace(`./`, ``).replace(`.svg`, ``),
      path: flagPaths(item),
    }));

    const moodPaths = require.context(`../assets/images/moods/`, false, /\.png$/);
    const moodImages = moodPaths.keys().map(item => ({
      dataImage: item.replace(`./`, ``).replace(`.png`, ``),
      path: moodPaths(item),
    }));

    return {
      flagImages,
      moodImages,
    };
  }
}

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import AppContext from './app-context';
import appReducer from './app-reducer';
import * as ACTIONS from './app-actions';
import initialAppState from '../config/state';

function AppState(props) {
  // ToDo: Check typings.
  const [state, dispatch] = useReducer(appReducer, initialAppState);

  const applyAllCorrections = () => {
    dispatch({ type: ACTIONS.CORRECTIONS_APPLY_ALL });
  };

  const applyCorrection = () => {
    dispatch({ type: ACTIONS.CORRECTIONS_APPLY });
  };

  const applySynonym = payload => {
    dispatch({ payload, type: ACTIONS.SYNONYMS_APPLY });
  };

  const discardCorrection = () => {
    dispatch({ type: ACTIONS.CORRECTIONS_DISCARD });
  };

  const setApiCorrections = payload => {
    dispatch({ payload, type: ACTIONS.API_SET_CORRECTIONS });
  };

  const setApiMoods = payload => {
    dispatch({ payload, type: ACTIONS.API_SET_MOODS });
  };

  const setCurrentCorrection = payload => {
    dispatch({ payload, type: ACTIONS.SET_CURRENT_CORRECTION });
  };

  const setCurrentCorrectionIndex = payload => {
    dispatch({ payload, type: ACTIONS.SET_CURRENT_CORRECTION_INDEX });
  };

  const setCurrentVerb = payload => {
    dispatch({ payload, type: ACTIONS.SET_CURRENT_VERB });
  };

  const setAutoCorrectError = payload => {
    dispatch({ payload, type: ACTIONS.ERRORS_SET_AUTOCORRECT });
  };

  const setGlobalLoading = payload => {
    dispatch({ payload, type: ACTIONS.SET_GLOBAL_LOADING });
  };

  const setLanguagesSource = payload => {
    dispatch({ payload, type: ACTIONS.LANGUAGES_SET_SOURCE });
  };

  const setLanguagesTarget = payload => {
    dispatch({ payload, type: ACTIONS.LANGUAGES_SET_TARGET });
  };

  const setSourceText = payload => {
    dispatch({ payload, type: ACTIONS.SET_SOURCE_TEXT });
  };

  const trimSourceText = () => {
    dispatch({ type: ACTIONS.TRIM_SOURCE_TEXT });
  };

  const toggleModal = payload => {
    dispatch({ payload, type: ACTIONS.MODAL_TOGGLE });
  };

  const sourceLanguageImage = () => {
    if (!state.apiCorrection.detected) {
      return state.languages.source.image;
    }

    if (state.apiCorrection.detected === `en`) {
      return state.assets.flags.find(item => item.dataImage === `en-US-UK`).path;
    }

    if (state.apiCorrection.detected === `de`) {
      return state.assets.flags.find(item => item.dataImage === `de`).path;
    }
  };

  const isFullyCorrected = () => {
    if (!state.sourceText) {
      return false;
    }

    return state.sourceText === state.apiCorrection.corrected;
  };

  const actions = {
    applyAllCorrections,
    applyCorrection,
    applySynonym,
    discardCorrection,
    setApiCorrections,
    setApiMoods,
    setAutoCorrectError,
    setCurrentCorrection,
    setCurrentCorrectionIndex,
    setCurrentVerb,
    setGlobalLoading,
    setLanguagesSource,
    setLanguagesTarget,
    setSourceText,
    toggleModal,
    trimSourceText,
  };
  const derived = { isFullyCorrected, sourceLanguageImage };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppContext.Provider value={{
      actions,
      derived,
      state,
    }}>
      { props.children }
    </AppContext.Provider>
  );
}

AppState.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default AppState;

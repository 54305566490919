/* eslint-disable react/forbid-prop-types */

import React from 'react';
import './Modal.scss';
import PropTypes from 'prop-types';

function Modal(props) {
  const className = props.backgroundGray ? `Modal Modal--gray` : `Modal`;

  return (
    <div
      className={className}
      style={props.modalStyle}>
      <div className="Modal__wrapper">
        {props.children}
      </div>
    </div>
  );
}

Modal.defaultProps = {
  backgroundGray: false,
  modalStyle: {},
};

Modal.propTypes = {
  backgroundGray: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  modalStyle: PropTypes.objectOf(PropTypes.any),
};

export default Modal;

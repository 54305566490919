import jwtDecode from 'jwt-decode';
import config from '../config/config';
import errors, { fallbackErrorMsg } from '../config/errors';
import i18n from '../i18n';

export default class CommonHelper {
  static assembleSourceLanguages(flagImages) {
    return config.languages.autoCorrect.map(lang => ({
      ...lang,
      image: flagImages.find(image => image.dataImage === lang.dataImage).path,
    }));
  }

  static assembleTargetLanguages(flagImages) {
    return config.languages.translation.map(lang => ({
      ...lang,
      image: flagImages.find(image => image.dataImage === lang.dataImage).path,
    }));
  }

  static assembleSingleLanguage(assets, dialect, lang) {
    const language = config.languages.autoCorrect.find(item => item.dialect === dialect) || lang;
    const icon = assets.find(item => item.dataImage === language.dataImage);

    return {
      ...language,
      image: icon.path,
    };
  }

  static deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static evaluateConditionsArray(arr) {
    const tempArr = arr.map(item => typeof item === `function` ? item() : item);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of tempArr) {
      if (!item) {
        return false;
      }
    }

    return true;
  }

  static replaceByIndex(start, end, input, repl) {
    return `${input.substr(0, start)}${repl}${input.substr(end, input.length)}`;
  }

  static transformMoodScore(score) {
    return score < 10 ? 10 : Math.round(score / 10) * 10;
  }

  static isTokenExpired(token) {
    return jwtDecode(token).exp * 1000 < Date.now();
  }

  static handleApiError(error) {
    const data = error.response.data?.error ?? error.response.data;
    const { code } = data;
    let message = errors.find(item => item.code === data.code)?.message ?? fallbackErrorMsg;

    if (code === 403) {
      const dialect = data.message.split(`: `)[1];
      const label = config.languages.translation.find(item => item.dialect.split(`-`)[0] === dialect)?.label ?? null;
      message = label ? i18n.t(message, { language: `${i18n.t(label)}` }) : i18n.t(message, { language: i18n.t(`languages.thisLanguage`) });
    } else {
      message = i18n.t(message);
    }

    return {
      apiMessage: data.message,
      code,
      message,
    };
  }
}

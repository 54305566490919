import * as ACTIONS from './app-actions';
import ReducerHelper from '../helpers/reducer-helper';
import CommonHelper from '../helpers/common-helper';

const appReducer = (state, action) => {
  const stateClone = CommonHelper.deepClone(state);
  let data = null;

  switch (action.type) {
    case ACTIONS.API_SET_CORRECTIONS:
      stateClone.apiCorrection = action.payload;

      return stateClone;
    case ACTIONS.API_SET_MOODS:
      stateClone.apiMoods = action.payload;

      return stateClone;
    case ACTIONS.CORRECTIONS_APPLY:
      data = ReducerHelper.applyCorrection(stateClone);

      stateClone.apiCorrection.edits = data.edits;
      stateClone.sourceText = data.text;

      stateClone.currentCorrection = null;

      return stateClone;
    case ACTIONS.CORRECTIONS_APPLY_ALL:
      stateClone.apiCorrection.edits = [];
      stateClone.sourceText = stateClone.apiCorrection.corrected;

      return stateClone;
    case ACTIONS.CORRECTIONS_DISCARD:
      stateClone.apiCorrection.edits = ReducerHelper.discardCorrection(stateClone);

      stateClone.currentCorrection = null;

      return stateClone;
    case ACTIONS.ERRORS_SET_AUTOCORRECT:
      stateClone.errors.autoCorrect = {
        ...stateClone.errors.autoCorrect,
        ...action.payload,
      };

      return stateClone;
    case ACTIONS.LANGUAGES_SET_SOURCE:
      stateClone.apiCorrection.detected = null;
      stateClone.languages.source = action.payload;
      return stateClone;
    case ACTIONS.LANGUAGES_SET_TARGET:
      stateClone.languages.target = action.payload;
      return stateClone;
    case ACTIONS.MODAL_TOGGLE:
      return {
        ...state,
        modals: ReducerHelper.toggleModal(stateClone, action.payload),
      };
    case ACTIONS.SET_CURRENT_CORRECTION:
      stateClone.currentCorrection = stateClone.apiCorrection.edits[action.payload] || action.payload;

      return stateClone;
    case ACTIONS.SET_CURRENT_CORRECTION_INDEX:
      stateClone.currentCorrectionIndex = action.payload;

      return stateClone;
    case ACTIONS.SET_CURRENT_VERB:
      stateClone.currentVerb = stateClone.apiCorrection.verbs[action.payload] || action.payload;

      return stateClone;
    case ACTIONS.SET_GLOBAL_LOADING:
      stateClone.globalLoading = action.payload;

      return stateClone;
    case ACTIONS.SET_SOURCE_TEXT:
      stateClone.apiCorrection.corrected = ``;
      stateClone.apiCorrection.edits = [];
      stateClone.apiCorrection.original = ``;
      stateClone.apiCorrection.verbs = [];
      stateClone.apiCorrection.detected = null;

      stateClone.apiMoods = [];

      stateClone.sourceText = action.payload;

      stateClone.currentCorrection = null;
      stateClone.currentVerb = null;

      return stateClone;
    case ACTIONS.SYNONYMS_APPLY:
      data = ReducerHelper.applySynonym(state, action.payload);

      stateClone.apiMoods = [];
      stateClone.apiCorrection.corrected = data.text;
      stateClone.apiCorrection.verbs = data.verbs;
      stateClone.sourceText = data.text;
      stateClone.currentVerb = null;

      return stateClone;
    case ACTIONS.TRIM_SOURCE_TEXT:
      stateClone.sourceText = stateClone.sourceText.replace(/\n*$/, ``);

      return stateClone;
    default:
      return stateClone;
  }
};

export default appReducer;

export const assembleDetectMoodPayload = state => ({
  dialect: state.apiCorrection.detected || state.languages.source.dialect,
  text: state.sourceText,
});

export const assembleContextualSynonymsPayload = state => ({
  dialect: state.apiCorrection.detected || state.languages.source.dialect,
  end: state.currentVerb.end,
  start: state.currentVerb.start,
  text: state.sourceText,
});

export const assembleAutoCorrectPayload = state => ({
  dialect: state.languages.source.dialect,
  text: state.sourceText,
  with: [`postag`],
});

export const assembleTranslationPayload = (state, text) => {
  const targetDialect = state.languages.source.dialect === `auto`
    ? state.apiCorrection.detected || `en`
    : state.languages.source.dialect;

  return {
    source: {
      dialect: state.languages.target.dialect,
      text,
    },
    target: {
      dialect: targetDialect,
    },
  };
};

import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './Translation.scss';
import { useTranslation } from 'react-i18next';
import TranslationFlag from './TranslationFlag';
import config from '../../config/config';
import AppContext from '../../context/app-context';
import TranslationCopy from './TranslationCopy';
import TranslationInput from './TranslationInput';
import CommonHelper from '../../helpers/common-helper';
import TranslationLanguageList from './TranslationLanguageList';
import useApi from '../hooks/useApi';
import FooterModal from '../common/FooterModal';
import { assembleTranslationPayload } from '../../helpers/request-helper';

function Translation() {
  const { state, actions } = useContext(AppContext);
  const [localState, setLocalState] = useState({
    listVisible: false,
    sourceText: ``,
    targetText: ``,
  });
  const { loading, response } = useApi({
    payload: assembleTranslationPayload(state, localState.sourceText),
    resource: config.api.typeright.resources.translate,
    signal: [localState.sourceText, state.languages.target.dialect],
  });
  const elModal = useRef(null);

  const modal = config.modals.TRANSLATION;
  const languages = CommonHelper.assembleTargetLanguages(state.assets.flags);
  const { t } = useTranslation();

  useEffect(() => {
    if (response) {
      setLocalState({ ...localState, targetText: response.targetText });
    }
  }, [response]);

  useEffect(() => {
    if (!state.apiCorrection.detected) {
      return;
    }

    const browserLanguage = navigator.language.split(`-`)[0];

    if (state.apiCorrection.detected === browserLanguage) {
      actions.setLanguagesTarget(languages.find(item => item.dialect === `af`));
      return;
    }

    actions.setLanguagesTarget(languages.find(item => item.dialect === navigator.language.split(`-`)[0]));
  }, [state.apiCorrection.detected]);

  useEffect(() => {
    actions.setLanguagesTarget(languages.find(item => item.dialect === `af`));
  }, []);

  const handleSetText = sourceText => {
    setLocalState({
      ...localState,
      sourceText,
      targetText: ``,
    });
  };

  const handleToggleList = () => {
    setLocalState({ ...localState, listVisible: !localState.listVisible });
  };

  const handleUpdateLanguage = dialect => {
    setLocalState({ ...localState, listVisible: !localState.listVisible });
    const newLanguage = languages.find(item => item.dialect === dialect);

    if (newLanguage.dialect === state.languages.target.dialect) {
      return;
    }

    actions.setLanguagesTarget(newLanguage);
  };

  const handleToggleModal = () => {
    setLocalState({
      listVisible: false,
      sourceText: ``,
      targetText: ``,
    });
    actions.toggleModal(modal);
  };

  const handleCloseModals = event => {
    if (!elModal.current) {
      return;
    }

    if (!elModal.current.contains(event.target)) {
      actions.toggleModal();
    }
  };

  useEffect(() => {
    if (state.modals[modal]) {
      document.addEventListener(`click`, handleCloseModals);
    }
    return () => document.removeEventListener(`click`, handleCloseModals);
  }, [state.modals[modal]]);

  return (
    <div className="Translation">
      {state.modals[modal] && (
        <div className={localState.listVisible ? `Translation__triangle Translation__triangle--dark` : `Translation__triangle`} />
      )}
      <div
        className="Translation__toggle"
        onClick={handleToggleModal} />
      {state.modals[modal] && (
        <FooterModal
          mode="right"
          ref={elModal}>
          <div className="Translation__content">
            <span className="Translation__content-heading">{ t(`translation.headline`) }</span>
            <div className="Translation__content-separator" />
            <div className="Translation__content-controls">
              <TranslationFlag
                handleToggleList={handleToggleList}
                image={state.languages.target.image} />
              <TranslationCopy
                loading={loading}
                sourceText={localState.sourceText}
                targetText={localState.targetText} />
            </div>
            <TranslationInput
              handleSetText={handleSetText}
              targetText={localState.targetText} />
            <TranslationLanguageList
              dialect={state.languages.target.dialect}
              handleToggleList={handleToggleList}
              handleUpdateLanguage={handleUpdateLanguage}
              languages={languages.filter(item => item.dialect !== state.languages.source.dialect && item.dialect !== state.apiCorrection.detected)}
              visible={localState.listVisible} />
          </div>
        </FooterModal>
      )}
    </div>
  );
}

export default Translation;

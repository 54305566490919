import i18n from '../i18n';
import { ApiError } from '../config/errors';

const transformAutoCorrection = response => {
  const apiEdits = response.result[0].edits.map(item => ({
    corrected: item.c_str,
    display: {
      correctedDisplay: item.cd_str,
      endDisplay: item.od_end,
      originalDisplay: item.od_str,
      startDisplay: item.od_start,
    },
    end: item.o_end,
    original: item.o_str,
    start: item.o_start,
    type: i18n.t(`mistakeTypes.${item.type.replaceAll(`:`, `_`)}`),
  }));

  let edits = [];

  for (let i = 0; i < apiEdits.length; i++) {
    if (apiEdits[i].start !== apiEdits[i].end) {
      edits.push(apiEdits[i]);
      continue;
    }

    /*
      * This block handles cases were a found error has the same start & end index.
      * If such an error was found it checks if it can either be appended to previous edit or next edit.
      * If neither is possible, it will be rendered via the display data returned by the backend.
      */
    const previousEdit = apiEdits[i - 1];
    const nextEdit = apiEdits[i + 1];

    if ((previousEdit?.end ?? -1) === apiEdits[i].end) {
      edits.push({
        ...apiEdits[i],
        corrected: `${previousEdit.corrected}${apiEdits[i].corrected}`,
        original: `${previousEdit.original}${apiEdits[i].original}`,
        start: previousEdit.start,
        type: `${apiEdits[i].type}, ${previousEdit.type}`,
      });

      edits = edits.filter(item => item !== previousEdit);
    } else if ((nextEdit?.start ?? -1) === apiEdits[i].start) {
      edits.push({
        ...apiEdits[i],
        corrected: `${apiEdits[i].corrected}${nextEdit.corrected}`,
        end: nextEdit.end,
        original: `${apiEdits[i].original}${nextEdit.original}`,
        type: `${apiEdits[i].type}, ${nextEdit.type}`,
      });

      i++;
    } else {
      edits.push({
        ...apiEdits[i],
        corrected: apiEdits[i].display.correctedDisplay,
        end: apiEdits[i].display.endDisplay,
        original: apiEdits[i].display.originalDisplay,
        start: apiEdits[i].display.startDisplay,
      });
    }
  }

  const apiPosTags = response.result[0].pos_tags.filter(item => item.pos_tag === `ADJ`);

  const verbs = apiPosTags.map((item, index) => ({
    end: item.start + item.token.length,
    index,
    start: item.start,
    word: item.token,
  }));

  // Ugly edge case handling since Spanish is supported on staging but not production.
  if (response.result[0].detected === `es`) {
    throw new ApiError({
      data: {
        code: 403,
        message: `Unsupported language detected: es`,
      },
    });
  }

  return {
    corrected: response.result[0].corrected_text,
    detected: response.result[0].detected || null,
    edits,
    original: response.text,
    verbs,
  };
};

const transformMoods = response => response.result.map(item => ({
  key: item.emotion,
  label: `moodDetection.${item.emotion}`,
  score: Math.round(item.score * 100),
})).splice(0, 3);

const transformSynonyms = response => response.result.map(item => item.token);

const transformTranslation = response => ({
  sourceDialect: response.source.dialect,
  sourceText: response.source.text,
  targetDialect: response.target.dialect,
  targetText: response.target.text,
});

export default {
  autocorrect: transformAutoCorrection,
  moodDetection: transformMoods,
  synonyms: transformSynonyms,
  translate: transformTranslation,
};

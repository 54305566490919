/* eslint-disable sort-keys */

import ImportHelper from '../helpers/import-helper';

// Initialize assets immediately to avoid race conditions.
const { moodImages, flagImages } = ImportHelper.importAssets();
// To avoid any 'undefined errors' on initial app render.
const initLang = {
  dataImage: ``,
  dialect: ``,
  image: ``,
  label: ``,
};

export default {
  // API
  apiCorrection: {
    corrected: ``,
    detected: null,
    edits: [],
    original: ``,
    verbs: [],
  },
  apiMoods: [],
  // UI
  currentCorrectionIndex: null,
  currentCorrection: null, // Reflects data displayed in the modal upon hover.
  currentVerb: null, // Reflects data displayed in the modal upon hover.
  errors: {
    autoCorrect: {
      content: ``,
      visible: false,
    },
  },
  globalLoading: false,
  modals: {
    emotions: false,
    errorCount: false,
    languageSwitch: false,
    translation: false,
  },
  // Assets
  assets: {
    flags: flagImages,
    moods: moodImages,
  },
  // User Data
  sourceText: ``,
  languages: {
    source: { ...initLang },
    target: { ...initLang },
  },
};

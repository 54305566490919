import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CorrectionBox.scss';

function CorrectionBox(props) {
  const className = props.className ? `CorrectionBox ${props.className}` : `CorrectionBox`;
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    props.onMouseEnter();
    setIsHovered(true);
  };

  const handleBlur = () => {
    props.onMouseLeave();
    setIsHovered(false);
  };

  const buttonsBaseJSX = (
    <>
      <button
        className="CorrectionBox__actions-discard"
        onClick={props.onDiscard} />
      <button
        className="CorrectionBox__actions-apply"
        onClick={props.onApply} />
    </>
  );

  // eslint-disable-next-line no-nested-ternary
  const buttonsJSX = props.isModal
    ? buttonsBaseJSX
    : (isHovered ? buttonsBaseJSX : <span className="CorrectionBox__error-type">{props.data.type}</span>);

  return (
    <div
      className={className}
      onMouseEnter={handleHover}
      onMouseLeave={handleBlur}>
      {props.isModal && <span className="CorrectionBox__error-type">{props.data.type}</span>}
      <div className="CorrectionBox__suggestion">
        <div className="CorrectionBox__text">
          <span className="CorrectionBox__text-wrong">{props.data.original}</span>
          <span className="CorrectionBox__text-correct">{props.data.corrected}</span>
        </div>
        <div className="CorrectionBox__actions">
          {buttonsJSX}
        </div>
      </div>
    </div>
  );
}

CorrectionBox.defaultProps = {
  className: ``,
  data: null,
  isModal: true,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

CorrectionBox.propTypes = {
  className: PropTypes.string,
  data: PropTypes.exact({
    corrected: PropTypes.string,
    original: PropTypes.string,
    type: PropTypes.string,
  }),
  isModal: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default CorrectionBox;

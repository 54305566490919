export const CORRECTIONS_APPLY = `CORRECTIONS_APPLY`;
export const CORRECTIONS_APPLY_ALL = `CORRECTIONS_APPLY_ALL`;
export const CORRECTIONS_DISCARD = `CORRECTIONS_DISCARD`;

export const API_SET_CORRECTIONS = `API_SET_CORRECTIONS`;
export const API_SET_MOODS = `API_SET_MOODS`;

export const ERRORS_SET_AUTOCORRECT = `ERRORS_SET_AUTOCORRECT`;

export const LANGUAGES_SET_SOURCE = `LANGUAGES_SET_SOURCE`;
export const LANGUAGES_SET_TARGET = `LANGUAGES_SET_TARGET`;

export const MODAL_TOGGLE = `MODAL_TOGGLE`;

export const SYNONYMS_APPLY = `SYNONYMS_APPLY`;

export const SET_CURRENT_CORRECTION = `SET_CURRENT_CORRECTION`;
export const SET_CURRENT_CORRECTION_INDEX = `SET_CURRENT_CORRECTION_INDEX`;
export const SET_CURRENT_VERB = `SET_CURRENT_VERB`;
export const SET_GLOBAL_LOADING = `SET_GLOBAL_LOADING`;
export const SET_SOURCE_TEXT = `SET_SOURCE_TEXT`;
export const TRIM_SOURCE_TEXT = `TRIM_SOURCE_TEXT`;

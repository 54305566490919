import React from 'react';
import './App.scss';
import CustomTextArea from './components/CustomTextArea';
import AppState from './context/AppState';
import ErrorList from './components/errorlist/ErrorList';

function App() {
  return (
    <div
      className="App"
      data-testid="app">
      <AppState>
        <CustomTextArea className="App__custom-text-area" />
        <ErrorList className="App__error-list" />
      </AppState>
    </div>
  );
}

export default App;

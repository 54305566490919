import axios from 'axios';

class ApiService {
  constructor(axiosConfig) {
    this.axios = axios.create(axiosConfig);
  }

  async post(requestConfig) {
    const resp = await this.axios.request({
      ...requestConfig,
      method: `POST`,
    });

    return resp.data;
  }
}

export default ApiService;

import CommonHelper from './common-helper';

export default class ReducerHelper {
  static applyCorrection(state) {
    const newText = CommonHelper.replaceByIndex(
      state.currentCorrection.start,
      state.currentCorrection.end,
      state.sourceText,
      state.currentCorrection.corrected,
    );
    const offset = state.currentCorrection.corrected.length - state.currentCorrection.original.length;
    // ToDo: Check if this breaks.
    const currentIndex = state.apiCorrection.edits.findIndex(item => JSON.stringify(item) === JSON.stringify(state.currentCorrection));
    const edits = state.apiCorrection.edits
      .filter((_, index) => index !== currentIndex)
      .map((item, index) => {
        if (index >= currentIndex) {
          return {
            ...item,
            end: item.end + offset,
            start: item.start + offset,
          };
        }
        return item;
      });

    return {
      edits,
      text: newText,
    };
  }

  static applySynonym(state, synonym) {
    // ToDo: Check if this breaks.
    const currentIndex = state.apiCorrection.verbs.findIndex(item => JSON.stringify(item) === JSON.stringify(state.currentVerb));
    const newText = CommonHelper.replaceByIndex(state.currentVerb.start, state.currentVerb.end, state.sourceText, synonym);
    const offset = synonym.length - state.currentVerb.word.length;

    // Filter applied synonym and adjust new indexes to account for replaced string.
    const newVerbs = state.apiCorrection.verbs
      .filter((item, index) => index !== currentIndex)
      .map((item, index) => {
        if (index >= currentIndex) {
          return {
            ...item,
            end: item.end + offset,
            start: item.start + offset,
          };
        }
        return item;
      });

    return {
      text: newText,
      verbs: newVerbs,
    };
  }

  static discardCorrection(state) {
    // ToDo: Check if this breaks.
    const currentIndex = state.apiCorrection.edits.findIndex(item => JSON.stringify(item) === JSON.stringify(state.currentCorrection));
    return state.apiCorrection.edits.filter((_, index) => index !== currentIndex);
  }

  static toggleModal(state, modal) {
    const current = state.modals[modal];
    const tempState = state;

    if (!modal) {
      // eslint-disable-next-line
      for (const item of Object.keys(state.modals)) {
        tempState.modals[item] = false;
      }

      return tempState.modals;
    }

    // eslint-disable-next-line
    for (const item of Object.keys(state.modals)) {
      tempState.modals[item] = false;
    }

    tempState.modals[modal] = !current;

    return tempState.modals;
  }
}

import React from 'react';
import './TranslationLanguage.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import checkmark from '../../assets/svgs/checkmark.svg';

function TranslationLanguage(props) {
  const imageIconClass = props.selected
    ? `LanguageItem__image-icon LanguageItem__image-icon--selected`
    : `LanguageItem__image-icon`;

  const imageLabelClass = props.selected
    ? `LanguageItem__image-label LanguageItem__image-label--selected`
    : `LanguageItem__image-label`;

  const { t } = useTranslation();

  return (
    <div
      className="LanguageItem"
      onClick={props.onClick}>
      <div className="LanguageItem__image">
        <img
          alt=""
          className={imageIconClass}
          src={props.image} />
        <span className={imageLabelClass}>{ t(props.label) }</span>
      </div>

      {props.selected && (
        <img
          alt=""
          src={checkmark} />
      )}
    </div>
  );
}

TranslationLanguage.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default TranslationLanguage;

import React from 'react';
import './MoodPreview.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function MoodPreview(props) {
  const { t } = useTranslation();

  return (
    <div className="MoodPreview">
      <span className="MoodPreview__heading">{ t(`moodDetection.headline`) }</span>
      <div className="MoodPreview__image" />
      <p className="MoodPreview__info">
        { t(`moodDetection.empty`) }
      </p>
      <button
        className="MoodPreview__button"
        onClick={props.handleToggleModal}>
        { t(`moodDetection.confirm`) }
      </button>
    </div>
  );
}

MoodPreview.propTypes = {
  handleToggleModal: PropTypes.func.isRequired,
};

export default MoodPreview;

import React, {
  useContext,
  useEffect,
  useRef,
} from 'react';
import Lottie from 'react-lottie';
import './ErrorCount.scss';
import { useTranslation } from 'react-i18next';
import AppContext from '../context/app-context';
import config from '../config/config';
import FooterModal from './common/FooterModal';

function ErrorCount() {
  const { actions, state } = useContext(AppContext);
  const classNameModifier = state.apiCorrection.edits.length > 0 ? `ErrorCount__count--negative` : `ErrorCount__count--positive`;
  const className = state.globalLoading ? `ErrorCount__count` : `ErrorCount__count ${classNameModifier}`;
  const modal = config.modals.ERROR_COUNT;
  const { t } = useTranslation();
  const elModal = useRef(null);

  const handleModalToggle = () => {
    if (state.apiCorrection.edits.length === 0) {
      return;
    }
    actions.toggleModal(modal);
  };

  const handleApply = () => {
    actions.toggleModal(modal);
    actions.applyAllCorrections();
  };

  const handleCloseModals = event => {
    if (!elModal.current) {
      return;
    }

    if (!elModal.current.contains(event.target)) {
      actions.toggleModal();
    }
  };

  useEffect(() => {
    if (state.modals[modal]) {
      document.addEventListener(`click`, handleCloseModals);
    }
    return () => document.removeEventListener(`click`, handleCloseModals);
  }, [state.modals[modal]]);

  return (
    <div className="ErrorCount">
      {state.modals[modal] && <div className="ErrorCount__triangle" />}
      <div
        className={className}
        onClick={handleModalToggle}>
        { state.globalLoading
          ? (
            <Lottie
              options={config.lottieDefaultOptions}
              style={{
                height: `16px`,
                lineHeight: `1`,
                width: `16px`,
              }} />
          )
          : state.apiCorrection.edits.length}
      </div>

      {state.modals[modal] && (
        <FooterModal
          key="someRandomKey"
          ref={el => { elModal.current = el; }}>
          <p className="ErrorCount__modal-text">{ t(`applyAll.prompt`) }</p>
          <button
            className="ErrorCount__modal-button"
            onClick={() => { handleModalToggle(); }}>
            { t(`applyAll.no`) }
          </button>
          <button
            className="ErrorCount__modal-button ErrorCount__modal-button--apply"
            onClick={handleApply}>
            { t(`applyAll.yes`) }
          </button>
        </FooterModal>
      )}
    </div>
  );
}

export default ErrorCount;

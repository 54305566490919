import React, { useContext } from 'react';
import './ErrorListEmpty.scss';
import { useTranslation } from 'react-i18next';
import icon from '../../assets/svgs/checkmark-white.svg';
import AppContext from '../../context/app-context';

function ErrorListEmpty() {
  const { t } = useTranslation();
  const { derived } = useContext(AppContext);
  const headline = derived.isFullyCorrected() ? t(`errorList.headline`) : t(`errorList.greeting`);
  const text = derived.isFullyCorrected() ? t(`errorList.noMistakes`) : t(`errorList.greetingSubline`);

  return (
    <div className="ErrorListEmpty">
      <div className="ErrorListEmpty__icon-container">
        <img
          alt=""
          className="ErrorListEmpty__icon"
          src={icon} />
      </div>
      <p className="ErrorListEmpty__headline">{ headline }</p>
      <p className="ErrorListEmpty__text">{ text }</p>
    </div>
  );
}

export default ErrorListEmpty;

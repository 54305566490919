import './CustomTextArea.scss';
import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import useApi from './hooks/useApi';
import CorrectionBox from './CorrectionBox';
import Modal from './common/Modal';
import SynonymsBox from './SynonymsBox';
import AppContext from '../context/app-context';
import config from '../config/config';
import UiHelper from '../helpers/ui-helper';
import Toast from './Toast';
import { assembleAutoCorrectPayload } from '../helpers/request-helper';
import Triangle from './common/Triangle';

function CustomTextArea(props) {
  const context = useContext(AppContext);
  const elOverlay = createRef();
  const elInput = createRef();
  const elCorrections = useRef([]);
  const elSynonyms = useRef([]);
  const correctionBlurTimeout = useRef(null);
  const synonymBlurTimeout = useRef(null);
  const [modalStyle, setModalStyle] = useState(null);
  const {
    error,
    loading,
    response,
  } = useApi({
    block: [context.state.apiCorrection.corrected],
    payload: assembleAutoCorrectPayload(context.state),
    resource: config.api.typeright.resources.autocorrect,
    signal: [context.state.sourceText],
  });
  const className = props.className ? `CustomTextArea ${props.className}` : `CustomTextArea`;

  const handleCorrectionBlur = () => {
    clearTimeout(correctionBlurTimeout.current);

    correctionBlurTimeout.current = setTimeout(() => {
      context.actions.setCurrentCorrection(null);
    }, 500);
  };

  const handleCorrectionHover = index => {
    clearTimeout(correctionBlurTimeout.current);
    context.actions.setCurrentCorrection(index);
    setModalStyle(UiHelper.getHoverModalStyle(elCorrections.current[index], elInput.current));
  };

  const handleSynonymBlur = () => {
    clearTimeout(synonymBlurTimeout.current);

    synonymBlurTimeout.current = setTimeout(() => {
      context.actions.setCurrentVerb(null);
    }, 500);
  };

  const handleSynonymHover = index => {
    clearTimeout(synonymBlurTimeout.current);
    context.actions.setCurrentVerb(index);
    setModalStyle(UiHelper.getHoverModalStyle(elSynonyms.current[index], elInput.current));
  };

  const handleScroll = event => {
    if (event.target.className.includes(`overlay`)) {
      elOverlay.current.scrollTop = elInput.current.scrollTop;
      return;
    }

    elOverlay.current.scrollTop = event.target.scrollTop;
  };

  useEffect(() => {
    if (response) {
      context.actions.setApiCorrections(response);
      context.actions.trimSourceText();

      // ToDo: Figure out why line breaks are rendered in overlay div.
      /*
       * This code currently syncs the scroll height of the overlay div as well as the text area.
       * Upon receiving a response both are set to the max possible scrollTop value.
       */
      elOverlay.current.scrollTop = 0;
      elInput.current.scrollTop = 0;
    }
  }, [response]);

  useEffect(() => {
    if (!error) {
      return;
    }

    context.actions.setAutoCorrectError({ message: error.message, visible: true });

    const timeout = setTimeout(() => {
      context.actions.setAutoCorrectError({ visible: false });
    }, 3000);

    return () => {
      context.actions.setAutoCorrectError({ visible: false });
      clearTimeout(timeout);
    };
  }, [error]);

  useEffect(() => {
    context.actions.setGlobalLoading(loading);
  }, [loading]);

  const { edits, verbs } = context.state.apiCorrection;
  let divContent = null;

  if (!context.state.globalLoading) {
    const jsxEdits = edits.map((item, index) => {
      const highlightClassName = index === context.state.currentCorrectionIndex
        || context.state.apiCorrection.edits[index] === context.state.currentCorrection
        ? `CustomTextArea__input-highlight CustomTextArea__input-highlight--active`
        : `CustomTextArea__input-highlight`;

      return (
        <div
          key={`input-highlight-${index}`}
          className={highlightClassName}
          ref={el => { elCorrections.current[index] = el; }}
          onMouseEnter={() => { handleCorrectionHover(index); }}
          onMouseLeave={handleCorrectionBlur}>
          {item.original}
          { context.state.currentCorrection === context.state.apiCorrection.edits[index] && (
            <Triangle
              elSpan={elCorrections.current[index]}
              size="large" />
          )}
        </div>
      );
    });

    divContent = UiHelper.assembleCorrections(edits, jsxEdits, context.state.sourceText);
  }

  if (!context.state.globalLoading && context.derived.isFullyCorrected()) {
    const mappedVerbs = verbs.map((item, index) => {
      const highlightClassName = context.state.apiCorrection.verbs[index] === context.state.currentVerb
        ? `CustomTextArea__input-highlight CustomTextArea__input-highlight--synonym CustomTextArea__input-highlight--synonym-active`
        : `CustomTextArea__input-highlight CustomTextArea__input-highlight--synonym`;

      return (
        <div
          key={`input-highlight-synonym-${index}`}
          className={highlightClassName}
          ref={el => { elSynonyms.current[index] = el; }}
          onMouseEnter={() => { handleSynonymHover(index); }}
          onMouseLeave={handleSynonymBlur}>
          {item.word}
          { context.state.currentVerb === context.state.apiCorrection.verbs[index] && (
            <Triangle
              elSpan={elSynonyms.current[index]}
              size="large" />
          )}
        </div>
      );
    });

    divContent = UiHelper.assembleSynonyms(verbs, mappedVerbs, context.state.sourceText);
  }

  return (
    <div className={className}>
      <div className="CustomTextArea__main">
        <div
          className="CustomTextArea__input CustomTextArea__input--overlay"
          ref={elOverlay}
          onScroll={handleScroll}>
          {error ? null : divContent}
        </div>
        <textarea
          className="CustomTextArea__input"
          data-enable-grammarly={false}
          data-gramm={false}
          data-gramm_editor={false}
          maxLength={1000}
          ref={elInput}
          spellCheck={false}
          value={context.state.sourceText}
          onChange={event => { context.actions.setSourceText(event.target.value); }}
          onScroll={handleScroll} />
        {context.state.currentCorrection && !context.state.globalLoading && (
          <Modal modalStyle={modalStyle}>
            <CorrectionBox
              data={{
                corrected: context.state.currentCorrection.display.correctedDisplay,
                original: context.state.currentCorrection.display.originalDisplay,
                type: context.state.currentCorrection.type,
              }}
              onApply={context.actions.applyCorrection}
              onDiscard={context.actions.discardCorrection}
              onMouseEnter={() => { clearTimeout(correctionBlurTimeout.current); }}
              onMouseLeave={handleCorrectionBlur} />
          </Modal>
        )}
        {context.state.currentVerb && (
          <Modal
            modalStyle={modalStyle}
            triangleStyle={{ left: `37.5%`, transform: `translateY(-15px)` }}
            topTriangle>
            <SynonymsBox
              key={`synonyms-box-${context.state.currentVerb.index}`}
              onMouseEnter={() => { clearTimeout(synonymBlurTimeout.current); }}
              onMouseLeave={handleSynonymBlur} />
          </Modal>
        )}
      </div>
      <Toast
        content={context.state.errors.autoCorrect.message}
        visible={context.state.errors.autoCorrect.visible} />
    </div>
  );
}

CustomTextArea.defaultProps = {
  className: ``,
};

CustomTextArea.propTypes = {
  className: PropTypes.string,
};

export default CustomTextArea;

import React from 'react';
import './TranslationFlag.scss';
import PropTypes from 'prop-types';

function TranslationFlag(props) {
  return (
    <div className="LanguageSelect">
      <div
        className="LanguageSelect__current"
        onClick={props.handleToggleList}>
        <img
          alt=""
          className="LanguageSelect__image"
          src={props.image} />
      </div>
    </div>
  );
}

TranslationFlag.propTypes = {
  handleToggleList: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
};

export default TranslationFlag;

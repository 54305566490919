import React, { useRef, useState } from 'react';
import './TranslationCopy.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import globeIcon from '../../assets/svgs/globe-gray.svg';
import loadingIcon from '../../assets/images/loading.png';
import copyIcon from '../../assets/svgs/copy.svg';

function TranslationCopy(props) {
  const [copyAlert, setCopyAlert] = useState(false);
  const alertTimeout = useRef(null);
  const { t, i18n } = useTranslation();

  // ToDo: Clean Up Classes
  const className = `CopyButton ${!props.loading && props.sourceText && `CopyButton--extended-${i18n.language}`}`.trim();
  const disabledClass = `CopyButton__gray ${!props.sourceText && `CopyButton__gray--visible`}`.trim();
  const loadingClass = `CopyButton__loading ${props.sourceText && props.loading && `CopyButton__loading--visible`}`.trim();
  const copyClass = `CopyButton__copy ${!props.loading && props.sourceText && `CopyButton__copy--visible`}`.trim();
  const alertClass = `CopyButton__alert ${copyAlert && `CopyButton__alert--visible`}`.trim();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(props.targetText);
    setCopyAlert(true);

    clearTimeout(alertTimeout.current);

    alertTimeout.current = setTimeout(() => {
      setCopyAlert(false);
    }, 3000);
  };

  return (
    <div className={className}>
      <img
        alt=""
        className={loadingClass}
        src={loadingIcon} />

      <img
        alt=""
        className={disabledClass}
        src={globeIcon} />

      <div
        className={copyClass}
        onClick={handleCopy}>
        <span>{ t(`translation.copy`) }</span>
        <img
          alt=""
          src={copyIcon} />
      </div>

      <span className={alertClass}>{ t(`translation.copied`) }</span>
    </div>
  );
}

TranslationCopy.propTypes = {
  loading: PropTypes.bool.isRequired,
  sourceText: PropTypes.string.isRequired,
  targetText: PropTypes.string.isRequired,
};

export default TranslationCopy;

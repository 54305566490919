import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import './SynonymsBox.scss';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import AppContext from '../context/app-context';
import config from '../config/config';
import useApi from './hooks/useApi';
import { assembleContextualSynonymsPayload } from '../helpers/request-helper';

function SynonymsBox(props) {
  const { state, actions } = useContext(AppContext);
  const [synonyms, setSynonyms] = useState([]);
  const { loading, response } = useApi({
    payload: assembleContextualSynonymsPayload(state),
    resource: config.api.typeright.resources.synonyms,
    signal: [true],
    timeout: 0,
  });
  const { t } = useTranslation();

  const handleApply = synonym => {
    actions.applySynonym(synonym);
  };

  // ToDo: Persist to global state to avoid unnecessary fetch requests.
  useEffect(() => {
    if (!response) {
      return;
    }

    setSynonyms(response.sort((a, b) => b.length - a.length));
  }, [response]);

  return (
    <div
      className="SynonymsBox"
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}>
      <div className="SynonymsBox__header">
        { t(`synonyms.label`) }
        <span className="SynonymsBox__header-key">{state.currentVerb.word}</span>
        :
      </div>

      {loading ? (
        <Lottie
          options={config.lottieDefaultOptions}
          style={{
            boxSizing: `content-box`,
            height: `40px`,
            padding: `15px 0 9px 0`,
            width: `40px`,
          }}
          isClickToPauseDisabled />
      ) : (
        <div className="SynonymsBox__synonyms">
          {synonyms.map((item, index) => (
            <div
              key={`synonyms-item-${index}`}
              className="SynonymsBox__synonyms-item"
              onClick={() => handleApply(item)}>
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

SynonymsBox.propTypes = {
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default SynonymsBox;

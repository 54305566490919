import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import emotionBadge from '../../assets/images/moods/mood-badge.png';
import './MoodItem.scss';
import AppContext from '../../context/app-context';
import CommonHelper from '../../helpers/common-helper';

function MoodItem(props) {
  const context = useContext(AppContext);

  const score = CommonHelper.transformMoodScore(props.mood.score);
  const moodImage = context.state.assets.moods.find(mood => mood.dataImage === `mood-${props.mood.key}`);
  const scoreImage = context.state.assets.moods.find(mood => mood.dataImage === `mood-percentage_${score}`);

  return (
    <div className="SingleMood">
      <img
        alt=""
        className="SingleMood__badge"
        src={emotionBadge} />
      <img
        alt=""
        className="SingleMood__score"
        src={scoreImage.path} />
      <img
        alt=""
        className="SingleMood__emotion"
        src={moodImage.path} />
    </div>
  );
}

MoodItem.propTypes = {
  mood: PropTypes.exact({
    key: PropTypes.string,
    label: PropTypes.string,
    score: PropTypes.number,
  }).isRequired,
};

export default MoodItem;
